import { GLOBAL_CONFIG } from 'src/app/configs/global.config';

export const environment = {
  production: false,
  api_url: 'https://gw-dev.cube.rina.org/inspection-reporting',
  admin_tool_url: 'https://gw-dev.cube.rina.org/admin-tool',
  ...GLOBAL_CONFIG,
  powerbi: {
    groupId: '767f863f-1f1c-42b7-8374-f3faa73cc104',
    reportId: '00449104-805f-4457-8ddf-0472a4c8a688',
    pageNameDashboard: 'a5b009c9054e02db83c4',
    pageNameAvanzamentiComplessivi: 'ReportSection64e0a05dab4a9444da42',
    pageNameAvanzamentiVisivi: '283ef95db0d2e41a989d',
    pageNameAvanzamentiStrumentali: '081aa26d8201719a8c0c',
    pageNameIncidenzaDifetti: 'ReportSection376dd2ec3a9a4067489a',
    pageNameAnalisiDifetti: 'c8c689d512960238622f',
    pageNameElencoDispositivi: '232fb4f43311c0a5a9bd',
  },
  b2cPolicies: {
    baseUrl : 'https://test-login.rina.org/rinagroupb2cdevl.onmicrosoft.com/b2c_1a_signup_signin/oauth2/v2.0/',
    tenant : 'rinagroupb2cdevl',
    clientId: '985bb9e1-aadc-4aab-8777-40b8a7163846',
    tenantId: 'c7788286-49f4-40db-bf26-fc1c677df787',
    redirectUri: 'https://inspection-reporting-dev.cube.rina.org',
    redirectCourtesyPageUri: 'https://www.rina.org/',
    postLogoutRedirectUri: 'https://www.rina.org/',
    names: {
      signUpSignIn: 'B2C_1A_SIGNUP_SIGNIN',
      resetPassword: 'B2C_1A_PASSWORDRESET',
    },
    authorities: {
      signUpSignIn: {
        authority: 'https://test-login.rina.org/rinagroupb2cdevl.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN',
      },
      resetPassword: {
        authority: 'https://test-login.rina.org/rinagroupb2cdevl.onmicrosoft.com/B2C_1A_PASSWORDRESET',
      },
      editProfile: {
        authority: 'https://test-login.rina.org/rinagroupb2cdevl.onmicrosoft.com/B2C_1A_PROFILEEDIT',
      },
    },
    domain: 'https://test-login.rina.org/',
    subdomain: 'rinagroupb2cdevl.onmicrosoft.com',
    authorityDomain: 'https://test-login.rina.org/rinagroupb2cdevl.onmicrosoft.com',
  },
  localStorageKeyPrefix: 'cube-mt-dev',
};
