import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FileService } from 'src/app/providers/services/file.service';
import { FileActions } from '../actions/file.actions';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { CubeBaseEffects } from 'src/app/abstractions/cube-base-effects.service';

@Injectable()
export class FileEffects extends CubeBaseEffects {
  constructor(
    private readonly fileService: FileService,
    private readonly actions$: Actions
  ) {
    super();
  }

  retrieveFileSASToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FileActions.retrieveSASTokenUrl),
      switchMap(({ url }) =>
        this.fileService.retrieveUrlSASToken(url).pipe(
          map(({ data }) => FileActions.retrieveSASTokenUrlSuccess({ url: data })),
          catchError((error: HttpErrorResponse) => of(FileActions.retrieveSASTokenUrlFailure({ error: this.handleError<unknown>(error) })))
        )
      )
    );
  });

  retrieveZIPUrl$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FileActions.retrieveZIPUrl),
      switchMap(({ id, resource }) =>
        this.fileService.downloadZIP(id, resource).pipe(
          map(({ data }) => FileActions.retrieveZIPUrlSuccess({ url: data })),
          catchError((error: HttpErrorResponse) => of(FileActions.retrieveZIPUrlFailure({ error: this.handleError<unknown>(error) })))
        )
      )
    );
  });

  urlToFileDownload$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FileActions.retrieveSASTokenUrlSuccess, FileActions.retrieveZIPUrlSuccess),
      map(({ url }) => FileActions.downloadFromUrl({ url }))
    );
  });

  downloadFromUrl$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FileActions.downloadFromUrl),
      switchMap(({ url }) =>
        this.fileService.downloadFromUrl(url).pipe(
          map((response: HttpResponse<Blob>) => FileActions.downloadFromUrlSuccess({ response })),
          catchError((error: HttpErrorResponse) => of(FileActions.downloadFromUrlFailure({ error: this.handleError<Blob>(error) })))
        )
      )
    );
  });

  downloadFromUrlSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FileActions.downloadFromUrlSuccess),
        tap(({ response }) => this.fileService.handleDownload(response))
      );
    },
    { dispatch: false }
  );
}
