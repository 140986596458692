import { createReducer, on } from '@ngrx/store';
import { SystemActions } from '../actions/system.actions';
import { SystemState } from '../states/system.state';

const INIT_STATE: SystemState = {
  currentLang: 'it',
  layoutMode: 'desktop',
  loading: false,
  notification: undefined,
  theme: 'dark',
};

export const systemReducers = createReducer(
  INIT_STATE,
  on(SystemActions.setCurrentLang, (state: SystemState, { currentLang }): SystemState => ({ ...state, currentLang })),
  on(SystemActions.setLayoutMode, (state: SystemState, { layoutMode }): SystemState => ({ ...state, layoutMode })),
  on(SystemActions.setNotification, (state: SystemState, { notification }): SystemState => ({ ...state, notification })),
  on(SystemActions.setTheme, (state: SystemState, { theme }): SystemState => ({ ...state, theme })),
  on(SystemActions.changeTheme, (state: SystemState): SystemState => ({ ...state, theme: state.theme === 'dark' ? 'light' : 'dark' })),
  on(SystemActions.startLoading, (state: SystemState): SystemState => ({ ...state, loading: true })),
  on(SystemActions.stopLoading, (state: SystemState): SystemState => ({ ...state, loading: false }))
);
