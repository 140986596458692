import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseModel } from 'src/app/models/response-model.type';
import { environment } from 'src/environments/environment';
import { TruncateFileUrlPipe } from '../pipes/truncate-file-url.pipe';

@Injectable({ providedIn: 'root' })
export class FileService {
  constructor(private readonly http: HttpClient) {}

  retrieveUrlSASToken(url: string): Observable<ResponseModel<string>> {
    const params: HttpParams = new HttpParams().appendAll({ url: encodeURIComponent(encodeURIComponent(url)) });
    return this.http.get<ResponseModel<string>>(`${environment.api_url}/OfflineFilesManagerHttpTrigger/file`, { params });
  }

  downloadZIP(id: string, resource: string): Observable<ResponseModel<string>> {
    const params: HttpParams = new HttpParams().appendAll({ id, resource });
    return this.http.get<ResponseModel<string>>(`${environment.api_url}/OfflineFilesManagerHttpTrigger/zip`, { params });
  }

  downloadFromUrl(url: string): Observable<HttpResponse<Blob>> {
    return this.http.get(url, { responseType: 'blob', observe: 'response' });
  }

  handleDownload(response: HttpResponse<Blob>) {
    if (response.body) {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(response.body);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      link.download = this.getFilename(response);
      link.click();
    }
  }

  private getFilename(response: HttpResponse<Blob>): string {
    if (response.body?.type === 'application/zip') {
      return 'attachments.zip';
    } else {
      const truncate_pipe = new TruncateFileUrlPipe();
      const filename = response.url?.includes('?')
        ? response.url?.split('?').at(0)?.split('/').at(-1)
        : truncate_pipe.transform(response.url || '');
      return filename || '';
    }
  }
}
